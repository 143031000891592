<script>
import { useNotificationStore } from '~/stores/account/notifications.store';
import MobileNotificationCard from './partials/mobile-notification-card.vue';
import MobileNotificationMarkAllRead from './partials/mobile-notification-mark-all-read.vue';
export default {
  name: "AccountNotifications",
  components: {
    MobileNotificationCard,
    MobileNotificationMarkAllRead,
  },
  data() {
    return {
      route: useRoute(),
      router: useRouter(),
      page: 1,
      notificationStore: useNotificationStore(),
      notifications: undefined,
      scrollPosition: 0,
      isLoadingPage: true,
      isLoadingData: false,
      isAtBottom: false,
      lastPage: false,
    }
  },
  methods: {
    async fetchNotifications() {
      return await this.notificationStore.fetchNotifications(this.route.query?.page || this.page);
    },
    checkScroll() {
      this.scrollPosition = window.scrollY;
      this.isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
    }
  },
  async mounted() {
    this.notifications = await this.fetchNotifications();
    window.addEventListener('scroll', this.checkScroll);
    this.isLoadingPage = false;
  },
  watch: {
    async 'isAtBottom'() {
      if (this.isLoadingData || this.notifications.pagination?.lastPage == this.page) {
        return;
      }
      this.isLoadingData = true;
      // Update query
      await this.router.push({
        query: { ...this.route.query, page: ++this.page },
      });

      // Fetch and update new page data
      const nextPageNotifications = await this.fetchNotifications()

      if (nextPageNotifications.data?.length) {
        this.notifications.data.push(...nextPageNotifications.data)
      }
      this.isLoadingData = false;
    }
  },
  async setup() {
  }
}
</script>

<template>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.notifications')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
        <template #header-right>
          <mobile-notification-mark-all-read></mobile-notification-mark-all-read>
        </template>
      </mobile-header>
    </template>

    <template v-if="isLoadingPage">
      <div class="relative h-screen">
        <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <span class="loader"></span>
        </div>
      </div>
    </template>

    <template v-else>
      <template v-if="notifications">
        <template v-for="(notification, idx) in notifications.data" :key="idx">
          <mobile-notification-card :data="notification"></mobile-notification-card>
        </template>
        <div class="absolute -bottom-5 left-1/2 -translate-x-1/2">
          <span class="loader" v-if="isLoadingData"></span>
        </div>
      </template>
      <template v-else>
        No Notification
      </template>
    </template>

    <template #mobile-footer></template>
  </NuxtLayout>
</template>